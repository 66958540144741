
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";

// Components
import TableData from "~/components/atoms/table/TableData.vue";

@Component({
  components: {
    TableData,
  },
})
export default class TableRowMolecule extends Vue {
  @Prop({ default: false }) colored: boolean;
  @Prop() data: [string];
  @Prop() index: [number];
  @Prop() showIcon: boolean;
  @Prop({ default: false }) hideMobileColumns: boolean;

  get classAdd() {
    return this.hideMobileColumns ? "hide-mobile" : "";
  }

  get classAddIndex() {
    return this.index?.length ? "is-indexed" : "";
  }
}
