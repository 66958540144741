
import { Vue, Component, Prop } from "nuxt-property-decorator";
import { returnPriceFormatDE, returPriceTrendFormatDE } from "~/mixins/mixins";

@Component({
  methods: {
    returnPriceFormatDE,
    returPriceTrendFormatDE,
  },
})
export default class PriceComparingTable extends Vue {
  @Prop() cityName: string;
  @Prop() tableHeaderData: [string];
  @Prop() tableRowData: { [key: string]: string | number | null }[];

  dividableByTwo(number: number): boolean {
    return number % 2 ? true : false;
  }
}
