export default [
  {
    name: "Preis",
    value: "price",
    direction: "asc",
    tooltip:
      "Alle folgenden Preise sind 100-Liter-Brutto-Endpreise inkl. 19 % MwSt. Es kommen keine Aufschläge hinzu.",
    infoPosition: "after",
  },
  {
    name: "Lieferzeit",
    value: "delivery-date",
    direction: "asc",
    tooltip:
      "Die Berechnung der Lieferzeiten basiert grundsätzlich auf den Tagen von Montag bis Freitag. Bitte beachten Sie, dass die Lieferfrist durch bundesweite und regionale Feiertage abweichen kann.",
    infoPosition: "before",
  },
  {
    name: "Zahlart",
    value: "payment-type",
    direction: "",
    tooltip: "",
    infoPosition: "none",
  },
  {
    name: "Bewertung",
    value: "rating",
    direction: "desc",
    tooltip: "",
    infoPosition: "none",
  },
];
