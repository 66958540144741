
// Libaries
import { Vue, Component, Prop } from "nuxt-property-decorator";

@Component({})
export default class OrderExtraInfo extends Vue {
  @Prop() icon: string;
  @Prop() altText: string;
  @Prop() width: string;
  @Prop() height: string;
}
