
// Libraries
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component({})
export default class PriceComparisonH1 extends Vue {
  @Prop() cityData: { name: string; zipcode: string };

  get lowerCaseCityName() {
    return this.cityData.name.toLocaleLowerCase();
  }

  /**
   * Check if the city name starts with a, b or c. If yes, it is a new headline AB test.
   */
  get isHeadlineFirstAbTest() {
    return /^[a-c]{1}/i.test(this.lowerCaseCityName);
  }

  get isHeadlineSecondAbTest() {
    return /^[d-z]{1}/i.test(this.lowerCaseCityName);
  }
}
