
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";

// Components
import OilImportTableRow from "~/components/molecules/table/OilImportTableRow.vue";

// Mixins

@Component({
  components: {
    OilImportTableRow,
  },
})
export default class OilImportTableOrganism extends Vue {
  @Prop() tableHeaderData: [string];
  @Prop() tableRowData: [string | number];
  @Prop({ default: false }) indexed: boolean;
  @Prop({ default: false }) showIcon;
  @Prop({ default: false }) hideMobileColumns: boolean;
  @Prop() figcaption: string;
  @Prop({ default: "default" }) variant: string;

  dividableByTwo(number: number) {
    if (number % 2) return true;
    return false;
  }

  get classAdd() {
    return this.hideMobileColumns ? "hide-mobile" : "";
  }
}
