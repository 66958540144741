
// Libraries
import { Component, Emit, Prop, Vue } from "nuxt-property-decorator";

@Component({})
export default class AkkordeonListItem extends Vue {
  @Prop({ default: "default" }) variant: string;
  @Prop({ default: "default" }) h3: string;
  @Prop({ default: false }) startSite: boolean;
  @Prop({ default: false }) dynamicHeight: boolean;
  @Prop({ default: false }) chartData: boolean;
  @Prop({ default: false }) isFaqSite: boolean;
  @Prop() id: number;
  @Prop() visibility: boolean;

  get startSiteContent() {
    if (this.startSite && this.visibility) return true;
    return false;
  }

  @Emit("changeVisibility")
  toggleVisibilityState() {
    return {
      visibility: this.visibility,
      startSite: this.startSite,
      id: this.id,
    };
  }
}
