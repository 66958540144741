
import { Vue, Component, Prop } from "nuxt-property-decorator";

@Component({})
export default class Tooltip extends Vue {
  @Prop({ required: true }) text: string;
  @Prop({ required: false, default: "" }) alternativeIcon: string;

  show: boolean = false;

  showTooltip() {
    this.show = true;
  }
  hideTooltip() {
    this.show = false;
  }
}
