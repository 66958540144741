
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import sendGtmEvent from "~/operations/send-gtm-event";

@Component({
  components: {
    RouterLink,
  },
})
export default class ShareNavOrganism extends Vue {
  [x: string]: any;
  @Prop({ default: true }) isShortVersion!: boolean;
  @Prop({ default: "" }) urlSharedPath!: string;
  @Prop({ required: true }) headline: string;
  @Prop({ required: true }) subject: string;
  @Prop({ required: true }) message: string;

  trackAnalyticsEvent(type: string = "") {
    sendGtmEvent(this, {
      category: "Sharing",
      label: type,
      action: "click",
    });
  }

  get sharedUrl() {
    return encodeURIComponent(
      this.urlSharedPath
        ? this.urlSharedPath
        : `${process.env.MAIN_ESYOIL}${this.$route.fullPath}`,
    );
  }
}
