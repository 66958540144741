
// Libaries
import { Vue, Component, Prop, namespace } from "nuxt-property-decorator";
import { ButtonText, PaymentTypes } from "~/operations/messages";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import SummedRating from "~/components/molecules/display/ratings/SummedRating.vue";
import DealerOfferDetailsOverlay from "~/components/organisms/display/DealerOfferDetailsOverlay.vue";

// Mixins
import { returnPriceFormatDE, returnFormattedDate } from "~/mixins/formatting";
import OrderExtraInfo from "~/components/molecules/display/OrderExtraInfo.vue";
import PaymentType from "./PaymentType.vue";
import { getKeyByValue } from "~/operations";
import { DealerData, PAYMENT_TYPES } from "~/types/Checkout";

const CheckoutModule = namespace("checkout");

@Component({
  scrollToTop: true,
  components: {
    RouterLink,
    SummedRating,
    OrderExtraInfo,
    PaymentType,
    // load component when preisdetails is clicked
    DealerOfferDetailsOverlay: () =>
      import("~/components/organisms/display/DealerOfferDetailsOverlay.vue"),
  },
  methods: {
    returnPriceFormatDE,
    returnFormattedDate,
  },
})
export default class DealerOfferItemMolecule extends Vue {
  @Prop() index: number;
  @Prop() fastestDelivery: string;
  @Prop() dealerData: DealerData;
  @Prop() dealRequirements: {
    amount: string;
    deliveryTimes: string;
    hose: string;
    payment_type: string;
    prod: string;
    short_vehicle: string;
    unloading_points: string;
    zipcode: string;
  };

  ButtonText = ButtonText;

  PaymentTypes = PaymentTypes;

  showPriceDetails = false;
  process = process;

  @CheckoutModule.Mutation("setValidComparsionPrice")
  setValidComparsionPrice;

  get COMPUTED_ENV_CDN_PATH() {
    return process.env.PARTNER_MEDIA_CDN;
  }

  initFallbackImage(event: Event) {
    const target = event.target as HTMLImageElement;
    if (target instanceof HTMLImageElement) {
      // eslint-disable-next-line no-undef
      target.src = require("~/assets/images/placeholders/no-logo.jpg");
    }
  }

  get totalAmount() {
    if (this.$route.query.amount) {
      return this.$route.query.amount;
    }
    return "3000";
  }

  getPaymentType() {
    let paymentType = this.$route.query.payment_type;
    switch (paymentType) {
      case PAYMENT_TYPES.all:
        return PaymentTypes[this.dealerData.dealer.defaultPaymentType];
      case PAYMENT_TYPES.cash:
      case PAYMENT_TYPES.invoice:
      case PAYMENT_TYPES.guaranteedPayment:
      case PAYMENT_TYPES.directDebit:
      case PAYMENT_TYPES.inAdvance:
        return PaymentTypes[paymentType];
      default:
        if (
          this.dealerData.dealer.requestedPaymentType &&
          this.dealerData.dealer.requestedPaymentType !== PAYMENT_TYPES.all
        ) {
          return PaymentTypes[this.dealerData.dealer.requestedPaymentType];
        }
        return PaymentTypes[this.dealerData.dealer.defaultPaymentType];
    }
  }

  get showOtherPaymentTypes(): boolean {
    return this.otherPaymentTypes.length > 1;
  }

  get paymentType(): string {
    return this.getPaymentType();
  }

  get hasDealerRating(): boolean {
    return this.dealerData.dealer.rating.averageRating > 0;
  }

  get paymentTypeImgUrl(): string {
    return `${process.env.PARTNER_MEDIA_CDN}/img/payment-types/${this.imageUrl}.svg`;
  }

  get imageUrl(): string {
    return this.getPaymentType();
  }

  get deliveryTime(): string {
    if (this.dealerData.delivery.type === "twentyFourHours") {
      return "(in 24 Stunden)";
    } else if (this.dealerData.delivery.durationDays === 0) {
      return "(Heute)";
    } else {
      return `(in ${this.dealerData.delivery.durationDays} Tagen)`;
    }
  }

  sortPaymentTypes(a: string, b: string): number {
    const sortOrder = [
      "ec",
      "cash",
      "invoice",
      "guaranteedPayment",
      "directDebit",
      "inAdvance",
    ];

    const aIndex = sortOrder.indexOf(a);
    const bIndex = sortOrder.indexOf(b);

    if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else {
      return aIndex - bIndex;
    }
  }

  get otherPaymentTypes(): string[] {
    const a = JSON.parse(
      JSON.stringify(this.dealerData.dealer.otherPaymentTypes),
    );
    return a.sort(this.sortPaymentTypes).map((paymentType: string) => {
      return PaymentTypes[paymentType];
    });
  }

  get paymentTypesMessage(): string {
    return this.otherPaymentTypes.length > 1
      ? `+${this.otherPaymentTypes.length - 1} Weitere Zahlarten`
      : "keine weiteren Zahlarten";
  }

  get hasValidImageSrc(): boolean {
    return !!this.dealerData.dealer.logoPath;
  }

  get imageSrc(): string {
    return this.hasValidImageSrc
      ? `${this.COMPUTED_ENV_CDN_PATH}${this.dealerData.dealer.logoPath}`
      : // eslint-disable-next-line no-undef
        require("~/assets/images/placeholders/no-logo.jpg");
  }

  togglePriceDetails() {
    this.showPriceDetails = !this.showPriceDetails;
    if (this.showPriceDetails === true) document.body.style.overflow = "hidden";
    if (this.showPriceDetails === false) document.body.style.overflow = "auto";
  }

  redirectToCheckout() {
    this.setValidComparsionPrice(true);

    this.$router.push({
      path: "/bestellung/heizoel-checkout",
      query: {
        amount: this.dealRequirements.amount,
        businessCustomerId: this.dealerData.dealer.id,
        businessCustomerSlug: this.dealerData.dealer.slug,
        deliveryAreaId: this.dealerData.dealer.daId,
        legacyId: String(this.dealerData.dealer.legacyId ?? -1),
        deliveryTimes: this.dealRequirements.deliveryTimes,
        preselecteddeliveryTimes: this.dealRequirements.deliveryTimes,
        hose: this.dealRequirements.hose,
        payment_type: getKeyByValue(PaymentTypes, this.paymentType),
        prod: this.dealRequirements.prod,
        preselectedProd: this.dealRequirements.prod,
        short_vehicle: this.dealRequirements.short_vehicle,
        unloading_points: this.dealRequirements.unloading_points,
        zipcode: this.dealRequirements.zipcode,
        checkoutStep: "0",
        numberOfRates: "12",
      },
    });
  }
}
