
import { Chart, Tooltip, registerables } from "chart.js";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import gradient from "chartjs-plugin-gradient";
import dayjs from "dayjs";
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";

@Component({})
export default class CityHistoryChart extends Vue {
  @Prop() chartLabels: Array<string | number>;
  @Prop() chartData: Array<Array<{ x: number; y: number }>>;
  @Prop() intersectionLabel: string;

  chartObject;

  get _chartLabels() {
    return this.chartLabels;
  }

  get _chartData() {
    return this.chartData;
  }

  mounted() {
    Chart.register(...registerables);
    Chart.register(gradient);
    this.registerCrossHairPlugin();
    this.createChart("cityhistory", this.chartjsOptions);
    //@ts-ignore
    Tooltip.positioners.custom = function (elements, eventPosition) {
      const tooltip = this;
      return {
        x: eventPosition.x,
        y: eventPosition.y,
      };
    };
  }

  @Watch("chartData")
  onCityUpdate() {
    this.createChart("cityhistory", this.chartjsOptions);
  }

  get cityhistoryTooltipHtmlElements() {
    return [
      document.getElementById("tooltip-p1"),
      document.getElementById("tooltip-p2"),
      document.getElementById("tooltip-p3"),
      document.getElementById("tooltip-p4"),
      document.getElementById("tooltip-p5"),
    ];
  }
  get cityhistoryTooltipColor() {
    return [
      document.getElementById("tooltip-c0"),
      document.getElementById("tooltip-c1"),
      document.getElementById("tooltip-c2"),
      document.getElementById("tooltip-c3"),
      document.getElementById("tooltip-c4"),
    ];
  }

  getTooltip = (chart) => {
    let tooltip = document.getElementById("my-tooltip");
    let tooltip_span = document.getElementById("tooltip-span");
    let xAlignLeft = window.innerWidth >= 680 ? 45 : 10;
    tooltip_span.innerHTML = chart.tooltip.title;
    if (chart.tooltip.x != undefined) {
      this.cityhistoryTooltipHtmlElements.forEach((ele, i) => {
        ele.innerHTML =
          chart.tooltip.dataPoints[i] === undefined
            ? null
            : `${chart.tooltip.dataPoints[i].dataset.label}: ${chart.tooltip.dataPoints[i].formattedValue} €`;
      });
    }

    tooltip.style.top = chart.tooltip.y + 35 + "px";

    tooltip.style.left =
      chart.tooltip.x +
      ((chart.tooltip.xAlign === "left" && chart.tooltip.yAlign === "bottom") ||
      (chart.tooltip.xAlign === "left" && chart.tooltip.yAlign === "top")
        ? xAlignLeft + 20
        : chart.tooltip.xAlign === "left"
        ? xAlignLeft
        : chart.tooltip.xAlign === "center"
        ? -95
        : -45) +
      "px";

    return tooltip;
  };

  externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;

    if (context.tooltip.x != undefined) {
      this.cityhistoryTooltipColor.forEach((ele, i) => {
        ele.style.backgroundColor =
          context.tooltip.labelColors[i] === undefined
            ? "transparent"
            : context.tooltip.labelColors[i].borderColor;
      });
    }

    const tooltipEL = this.getTooltip(chart);

    if (tooltip.opacity === 0 || tooltip.body[0].lines[0] === undefined) {
      tooltipEL.style.opacity = "0";
      tooltipEL.style.display = "none";
    } else {
      tooltipEL.style.opacity = "1";
      tooltipEL.style.display = "block";
    }
  };

  get chartjsOptions() {
    return {
      type: "line",
      data: {
        labels: this._chartLabels,
        datasets: [
          {
            label: "1000 L",
            data: this._chartData[0],
            borderColor: "#000",
            backgroundColor: "#000",
            borderWidth: 2,
            paddingBottom: 20,
            pointBorderColor: "#000",
            pointBackgroundColor: "#000",
            pointRadius: 0,
            steppedLine: false,
            fill: true,
            gradient: {
              backgroundColor: {
                axis: "y",
                colors: {
                  0: "rgba(142, 142,142, 0.0)",
                  100: "rgba(142, 142,142, 0.1)",
                },
              },
            },
          },
          {
            label: "4000 L",
            data: this._chartData[1],
            borderColor: "#F7C334",
            backgroundColor: "#F7C334",
            borderWidth: 2,
            pointBorderColor: "#F7C334",
            pointBackgroundColor: "#F7C334",
            pointRadius: 0,
            fill: true,
            steppedLine: false,
            gradient: {
              backgroundColor: {
                axis: "y",
                colors: {
                  0: "rgba(0, 209, 255, 0.0)",
                  100: "rgba(247, 195, 52, 0.1)",
                },
              },
            },
          },
          {
            label: "5000 L",
            data: this._chartData[2],
            borderColor: "#2BCC43",
            backgroundColor: "#2BCC43",
            borderWidth: 2,
            pointBorderColor: "#2BCC43",
            pointBackgroundColor: "#2BCC43",
            pointRadius: 0,
            steppedLine: false,
            gradient: {
              backgroundColor: {
                axis: "y",
                colors: {
                  0: "rgba(43, 204, 67, 0.0)",
                  100: "rgba(43, 204, 67, 0.1)",
                },
              },
            },
          },
          {
            label: "8000 L",
            data: this._chartData[3],
            borderColor: "#CA02FD",
            backgroundColor: "#CA02FD",
            borderWidth: 2,
            pointBorderColor: "#CA02FD",
            pointBackgroundColor: "#CA02FD",
            pointRadius: 0,
            steppedLine: false,
            gradient: {
              backgroundColor: {
                axis: "y",
                colors: {
                  0: "rgba(202, 2, 253, 0.0)",
                  100: "rgba(202, 2, 253, 0.1)",
                },
              },
            },
          },
          {
            label: "10000 L",
            data: this._chartData[4],
            borderColor: "#00D1FF",
            backgroundColor: "#00D1FF",
            borderWidth: 2,
            pointBorderColor: "#00D1FF",
            pointBackgroundColor: "#00D1FF",
            pointRadius: 0,
            steppedLine: false,
            gradient: {
              backgroundColor: {
                axis: "y",
                colors: {
                  0: "rgba(0, 209, 255, 0.0)",
                  100: "rgba(0, 209, 255, 0.1)",
                },
              },
            },
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: () => (window.innerWidth < 700 ? 0 : 5),
            left: () => (window.innerWidth < 700 ? 0 : 70),
            right: () => (window.innerWidth < 700 ? 0 : 5),
            bottom: () => (window.innerWidth < 700 ? 0 : 10),
          },
        },
        aspectRatio: () => (window.innerWidth < 700 ? 1 | 0.2 : 0.5 | 2),
        responsive: true,
        lineTension: 1,
        spanGaps: true,
        title: {
          display: false,
          text: "Heizölpreis-Entwicklung in Deutschland",
          fontSize: 22,
          position: "bottom" as const,
          padding: {
            top: 20,
            bottom: 30,
          },
        },

        hover: {
          mode: "index",
          intersect: false,
        },
        scales: {
          y: {
            position: "right",
            ticks: {
              beginAtZero: false,
              maxTicksLimit: 10,
              stepSize: 10,
              fontSize: 12,
              padding: () => (window.innerWidth < 700 ? 0 : 25),
              fontColor: "#1f2023",
              callback(value, index, values) {
                return `${value} €`;
              },
            },
            scaleLabel: {
              display: false,
              labelString: "€ / 100 L",
              fontColor: "#000000",
              fontSize: 12,
            },
            grid: {
              color: "rgba( 211, 211, 219, 0.4 )",
              drawBorder: false,
              drawOnChartArea: true,
              drawTicks: true,
              tickMarkLength: 10,
            },
          },

          x: {
            type: "category",
            distribution: "linear" as const,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              maxRotation: 0,
              fontSize: 12,
              fontColor: "#1f2023",
              padding: () => (window.innerWidth < 700 ? 0 : 25),
              align: "center",

              callback: (context) => {
                const splittedDate = this.chartLabels[context]
                  .toString()
                  .split(".");
                return dayjs(
                  `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`,
                ).format("MMM");
              },
            },
            scaleLabel: {
              display: false,
              labelString: "",
              fontColor: "#000000",
              fontSize: 12,
            },
            grid: {
              color: "rgba( 211, 211, 219, 0.4 )",
              zeroLineColor: "#cbd0d2",
              zeroLineBorderDash: [3, 9],
              drawBorder: true,
              drawOnChartArea: true,
              drawTicks: true,
              tickMarkLength: 20,
            },
          },
        },

        plugins: {
          legend: {
            display: true,
            position: "top" as const,
            align: "center" as const,
            labels: {
              fontColor: "rgba(0,0,0)",
              usePointStyle: true,
              pointStyle: "line",
              boxWidth: 50,
              padding: 20,
              font: {
                size: 12,
              },
            },
          },
          tooltip: {
            enabled: false,
            external: this.externalTooltipHandler,
            mode: "index",
            intersect: false,
            position: "custom",
            displayColors: true,
            xPadding: 20,
            yPadding: 20,
            caretPadding: 5,
            cornerRadius: 5,
            borderWidth: 1,
            usePointStyle: true,
            titleColor: "rgba(0, 0, 0, 0.8)",
            backgroundColor: "#fff",
            bodyColor: "rgba(0, 0, 0, 0.8)",
            bodySpacing: 5,
            borderColor: "#000",
            callbacks: {
              label: function (tooltipItem, data) {
                return `${tooltipItem.dataset.label}:${tooltipItem.formattedValue} €`;
              },
            },
          },
          crosshair: {
            sync: {
              enabled: false,
            },
            zoom: {
              enabled: false,
            },
            line: {
              color: "hsla( 10, 1%, 1%, 1 )",
              width: 1,
              dashPattern: [10, 3],
            },
          },
        },
      },
    };
  }
  // Add some spacing between the labels legend texts and chart
  legendPlugin: any = {
    beforeInit(chart) {
      const originalFit = chart.legend.fit;

      // Override the fit function
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 10;
      };
    },
  };

  createChart(chartId: string, chartData: any) {
    if (this.chartObject) {
      this.chartObject.destroy();
    }
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;
    this.chartObject = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
      plugins: [this.legendPlugin],
    });
  }

  registerCrossHairPlugin() {
    Chart.register(CrosshairPlugin);
  }
}
